<template>
  <!-- 置顶的分析结果 -->
  <table class="result table_column" v-show="showFixedResult">
    <thead>
      <tr>
        <th>身属性</th>
        <th>身强弱</th>
        <th>阴阳</th>
        <th>月令同反</th>
        <th>喜用</th>
        <th>忌凶</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td :class="rigan">{{ rigan }}</td>
        <td>{{ score }}</td>
        <td>{{ yinyang }}</td>
        <td>{{ yuelingtongfan }}</td>
        <td class="yongshen">
          <span v-for="item in xiyongshen" :key="item" :class="item">{{
            item
          }}</span>
        </td>
        <td class="yongshen">
          <span v-for="item in jixiongshen" :key="item" :class="item">{{
            item
          }}</span>
        </td>
      </tr>
    </tbody>
  </table>
  <div class="container">
    <div class="header">
      <div class="title">日期：</div>
      <div class="date">
        <input type="date" v-model="solar" />
      </div>
      <div class="title">时间：</div>
      <div class="time">
        <select v-model="hour">
          <option v-for="item in hours" :key="item.index" :value="item.index">
            {{ item.time }}
          </option>
        </select>
      </div>
      <div class="male">
        <select v-model="male">
          <option value="1">男</option>
          <option value="0">女</option>
        </select>
      </div>
      <div class="calc">
        <button class="start_calc" @click="calc">计算</button>
      </div>
    </div>
    <div class="content" v-show="showResult">
      <!-- 农历信息 -->
      <table class="table_column">
        <thead>
          <tr>
            <th>农历</th>
            <th>节气</th>
            <th>生肖</th>
            <th>行运始于公历</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{{ lunar }}</td>
            <td>{{ term }}</td>
            <td>{{ zodiac }}</td>
            <td>
              {{ liunianFirstDay[0] }} 年 {{ liunianFirstDay[1] }} 月
              {{ liunianFirstDay[2] }} 日
            </td>
          </tr>
        </tbody>
      </table>
      <!-- 四柱 -->
      <table class="sizhu">
        <thead>
          <tr>
            <th>四柱</th>
            <th>年柱</th>
            <th>月柱</th>
            <th>日柱</th>
            <th>时柱</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th>天干</th>
            <td :class="niangan">{{ niangan }}</td>
            <td :class="yuegan">{{ yuegan }}</td>
            <td :class="rigan">{{ rigan }}</td>
            <td :class="shigan">{{ shigan }}</td>
          </tr>
          <tr>
            <th>地支</th>
            <td :class="nianzhi">{{ nianzhi }}</td>
            <td :class="yuezhi">{{ yuezhi }}</td>
            <td :class="rizhi">{{ rizhi }}</td>
            <td :class="shizhi">{{ shizhi }}</td>
          </tr>
        </tbody>
      </table>
      <!-- 分析结果 -->
      <table class="table_column">
        <thead>
          <tr>
            <th>身属性</th>
            <th>身强弱</th>
            <th>阴阳</th>
            <th>月令同反</th>
            <th>喜用</th>
            <th>忌凶</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td :class="rigan">{{ rigan }}</td>
            <td>{{ score }}</td>
            <td>{{ yinyang }}</td>
            <td>{{ yuelingtongfan }}</td>
            <td class="yongshen">
              <span v-for="item in xiyongshen" :key="item" :class="item">{{
                item
              }}</span>
            </td>
            <td class="yongshen">
              <span v-for="item in jixiongshen" :key="item" :class="item">{{
                item
              }}</span>
            </td>
          </tr>
        </tbody>
      </table>
      <!-- 纳音 -->
      <table>
        <thead>
          <tr>
            <th colspan="4">纳音</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td v-for="(item, index) in nayinArray" :key="index">{{ item }}</td>
          </tr>
        </tbody>
      </table>
      <!-- 六甲空亡 -->
      <table>
        <thead>
          <tr>
            <th colspan="4">六甲空亡</th>
          </tr>
        </thead>
        <tbody>
          <td v-for="(item, index) in kongwan" :key="index">
            <span v-for="el in item" :key="el" :class="el">{{ el }}</span>
          </td>
        </tbody>
      </table>
      <!-- 神煞 -->
      <table class="shensha">
        <thead>
          <tr>
            <th colspan="4">神煞</th>
          </tr>
        </thead>
        <tbody>
          <td>
            <div v-for="(item, index) in nianzhushensha" :key="index">
              <div v-for="el in item" :key="el" :class="el">{{ el }}</div>
            </div>
          </td>
          <td>
            <div v-for="(item, index) in yuezhushensha" :key="index">
              <div v-for="el in item" :key="el" :class="el">{{ el }}</div>
            </div>
          </td>
          <td>
            <div v-for="(item, index) in rizhushensha" :key="index">
              <div v-for="el in item" :key="el" :class="el">{{ el }}</div>
            </div>
          </td>
          <td>
            <div v-for="(item, index) in shizhushensha" :key="index">
              <div v-for="el in item" :key="el" :class="el">{{ el }}</div>
            </div>
          </td>
        </tbody>
      </table>
      <!-- 十神 -->
      <table>
        <thead>
          <tr>
            <th>十神</th>
            <th v-for="(item, index) in shishen" :key="index">{{ item }}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th>藏干</th>
            <td v-for="(item, index) in canggan" :key="index">
              <span v-for="el in item" :key="el" :class="el">{{ el }}</span>
            </td>
          </tr>
          <tr>
            <th>十神</th>
            <td v-for="(item, index) in cangganshishen" :key="index">
              <div v-for="el in item" :key="el">{{ el }}</div>
            </td>
          </tr>
        </tbody>
      </table>
      <!-- 行运岁数 -->
      <table class="xingyunsuishu table_with_fixed_header">
        <div class="fixed_header">
          <table>
            <thead>
              <tr>
                <th>行运岁数</th>
              </tr>
              <tr>
                <th>大运干支</th>
              </tr>
              <tr>
                <th>大运十神</th>
              </tr>
              <tr>
                <th>大运藏干</th>
              </tr>
              <tr>
                <th>藏干十神</th>
              </tr>
            </thead>
          </table>
        </div>
        <div class="scroll">
          <table>
            <thead>
              <tr>
                <th
                  v-for="(item, index) in xingyunsuishu"
                  :key="index"
                  @click="calcLiunian(index)"
                  class="tab"
                  :class="{ selected: selectedOld == index ? true : false }"
                >
                  {{ item }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td
                  v-for="(item, index) in dayunganzhi"
                  :key="index"
                  :class="{ selected_td: selectedOld == index ? true : false }"
                >
                  <span v-for="el in item" :key="el" :class="el">{{ el }}</span>
                </td>
              </tr>
              <tr>
                <td
                  v-for="(item, index) in dayunshishen"
                  :key="index"
                  :class="{ selected_td: selectedOld == index ? true : false }"
                >
                  {{ item }}
                </td>
              </tr>
              <tr>
                <td
                  v-for="(item, index) in dayuncanggan"
                  :key="index"
                  :class="{ selected_td: selectedOld == index ? true : false }"
                >
                  <span v-for="el in item" :key="el" :class="el">{{ el }}</span>
                </td>
              </tr>
              <tr>
                <td
                  v-for="(item, index) in dayuncangganshishen"
                  :key="index"
                  :class="{ selected_td: selectedOld == index ? true : false }"
                >
                  <div v-for="el in item" :key="el">{{ el }}</div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </table>
      <!-- 流年 -->
      <table class="table_with_fixed_header">
        <div class="fixed_header">
          <table>
            <thead>
              <tr>
                <th>流年</th>
              </tr>
              <tr>
                <th>干支</th>
              </tr>
              <tr>
                <th>十神</th>
              </tr>
              <tr>
                <th>藏干</th>
              </tr>
              <tr>
                <th>十神</th>
              </tr>
            </thead>
          </table>
        </div>
        <div class="scroll">
          <table>
            <thead>
              <tr>
                <th
                  v-for="(item, index) in liunianArray"
                  :key="index"
                  @click="calcLiuyue(item, index)"
                  class="tab"
                  :class="{ selected: selectedYear == item ? true : false }"
                >
                  {{ item }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td
                  v-for="(item, index) in liunianganzhi"
                  :key="index"
                  :class="{
                    selected_td: selectedYearIndex == index ? true : false,
                  }"
                >
                  <span v-for="el in item" :key="el" :class="el">{{ el }}</span>
                </td>
              </tr>
              <tr>
                <td
                  v-for="(item, index) in liunianshishen"
                  :key="index"
                  :class="{
                    空: item == '空' ? true : false,
                    selected_td: selectedYearIndex == index ? true : false,
                  }"
                >
                  {{ item }}
                </td>
              </tr>
              <tr>
                <td
                  v-for="(item, index) in liuniancanggan"
                  :key="index"
                  :class="{
                    selected_td: selectedYearIndex == index ? true : false,
                  }"
                >
                  <span v-for="el in item" :key="el" :class="el">{{ el }}</span>
                </td>
              </tr>
              <tr>
                <td
                  v-for="(item, index) in liuniancangganshishen"
                  :key="index"
                  :class="{
                    selected_td: selectedYearIndex == index ? true : false,
                  }"
                >
                  <div v-for="el in item" :key="el" :class="el">{{ el }}</div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </table>
      <!-- 流月 -->
      <table class="table_with_fixed_header">
        <div class="fixed_header">
          <table>
            <thead>
              <tr>
                <th>流月</th>
              </tr>
              <tr>
                <th>干支</th>
              </tr>
              <tr>
                <th>十神</th>
              </tr>
              <tr>
                <th>藏干</th>
              </tr>
              <tr>
                <th>十神</th>
              </tr>
            </thead>
          </table>
        </div>
        <div class="scroll">
          <table>
            <thead>
              <tr>
                <th
                  v-for="(item, index) in liuyueArray"
                  :key="index"
                  @click="calcLiuri(item, index)"
                  class="tab"
                  :class="{ selected: selectedMonth == item ? true : false }"
                >
                  {{ item }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td
                  v-for="(item, index) in liuyueganzhi"
                  :key="index"
                  :class="{
                    selected_td: selectedMonthIndex == index ? true : false,
                  }"
                >
                  <span v-for="el in item" :key="el" :class="el">{{ el }}</span>
                </td>
              </tr>
              <tr>
                <td
                  v-for="(item, index) in liuyueshishen"
                  :key="index"
                  :class="{
                    空: item == '空' ? true : false,
                    selected_td: selectedMonthIndex == index ? true : false,
                  }"
                >
                  {{ item }}
                </td>
              </tr>
              <tr>
                <td
                  v-for="(item, index) in liuyuecanggan"
                  :key="index"
                  :class="{
                    selected_td: selectedMonthIndex == index ? true : false,
                  }"
                >
                  <span v-for="el in item" :key="el" :class="el">{{ el }}</span>
                </td>
              </tr>
              <tr>
                <td
                  v-for="(item, index) in liuyuecangganshishen"
                  :key="index"
                  :class="{
                    selected_td: selectedMonthIndex == index ? true : false,
                  }"
                >
                  <div v-for="el in item" :key="el" :class="el">{{ el }}</div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </table>
      <!-- 流日 -->
      <div class="liuri table_with_fixed_header">
        <div class="fixed_header">
          <table>
            <thead>
              <tr>
                <th>流日</th>
              </tr>
              <tr>
                <th>年干支</th>
              </tr>
              <tr>
                <th>月干支</th>
              </tr>
              <tr>
                <th>日干支</th>
              </tr>
              <tr>
                <th>十神</th>
              </tr>
              <tr>
                <th>藏干</th>
              </tr>
              <tr>
                <th>十神</th>
              </tr>
            </thead>
          </table>
        </div>
        <div class="scroll">
          <table>
            <thead>
              <tr>
                <th v-for="(item, index) in liuriArray" :key="index">
                  {{ item }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td v-for="(item, index) in liuriganzhiYear" :key="index">
                  <span v-for="el in item" :key="el" :class="el">{{ el }}</span>
                </td>
              </tr>
              <tr>
                <td v-for="(item, index) in liuriganzhiMonth" :key="index">
                  <span v-for="el in item" :key="el" :class="el">{{ el }}</span>
                </td>
              </tr>
              <tr>
                <td v-for="(item, index) in liuriganzhi" :key="index">
                  <span v-for="el in item" :key="el" :class="el">{{ el }}</span>
                </td>
              </tr>
              <tr>
                <td
                  v-for="(item, index) in liurishishen"
                  :key="index"
                  :class="{ 空: item == '空' ? true : false }"
                >
                  {{ item }}
                </td>
              </tr>
              <tr>
                <td v-for="(item, index) in liuricanggan" :key="index">
                  <span v-for="el in item" :key="el" :class="el">{{ el }}</span>
                </td>
              </tr>
              <tr>
                <td v-for="(item, index) in liuricangganshishen" :key="index">
                  <div v-for="el in item" :key="el" :class="el">{{ el }}</div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import data from "./Data.js";
import lunarCalendar from "lunar-calendar";
import solarLunar from "solarlunar";
const dizhi = data.dizhi;
const shizhubiao = data.shizhubiao;
const liushijiazi = data.liushijiazi;
const liujiakongwang = data.liujiakongwang;
// const nayinbiao = data.nayinbiao;
export default {
  name: "Main",
  data() {
    return {
      solar: "",
      year: 0,
      month: 0,
      day: 0,
      hour: 24,
      male: "1",
      hours: [
        { index: 24, time: "晚上 11:00 ~ 凌晨 01:00" },
        { index: 2, time: "凌晨 01:00 ~ 凌晨 03:00" },
        { index: 4, time: "凌晨 03:00 ~ 早上 05:00" },
        { index: 6, time: "早上 05:00 ~ 早上 07:00" },
        { index: 8, time: "早上 07:00 ~ 上午 09:00" },
        { index: 10, time: "上午 09:00 ~ 中午 11:00" },
        { index: 12, time: "中午 11:00 ~ 下午 01:00" },
        { index: 14, time: "下午 01:00 ~ 下午 03:00" },
        { index: 16, time: "下午 03:00 ~ 下午 05:00" },
        { index: 18, time: "下午 05:00 ~ 晚上 07:00" },
        { index: 20, time: "晚上 07:00 ~ 晚上 09:00" },
        { index: 22, time: "晚上 09:00 ~ 晚上 11:00" },
      ],
      // 农历年月日
      lunarYear: 0,
      lunarMonth: 0,
      lunarDay: 0,
      // 农历日期、节气、生肖
      lunar: "",
      term: "",
      zodiac: "",
      // 四柱
      nianzhu: "",
      yuezhu: "",
      rizhu: "",
      shizhu: "",
      // 四天干，四地支
      niangan: "",
      nianzhi: "",
      yuegan: "",
      yuezhi: "",
      rigan: "",
      rizhi: "",
      shigan: "",
      shizhi: "",
      // 分析
      score: 0,
      yinyang: "",
      yuelingtongfan: "",
      xiyongshen: "",
      jixiongshen: "",
      // 纳音
      nayinArray: [],
      // 十神
      shishen: [],
      canggan: [],
      cangganshishen: [],
      // 行运开始年，十年一大运
      xingyunnian: 0,
      xingyunsuishu: [],
      dayunganzhi: [],
      dayunshishen: [],
      dayuncanggan: [],
      dayuncangganshishen: [],
      // 流年的第一天
      liunianFirstDay: [],
      // 流年的数组
      liunianArray: [],
      // 流月的数组
      liuyueArray: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
      // 流日的数组
      liuriArray: [],
      // 选中的行运岁数----
      selectedOld: 0,
      // 选中的流年
      selectedYear: 0,
      selectedYearIndex: 0,
      // 选中的流月 -------
      selectedMonth: 0,
      selectedMonthIndex: 0,
      // 流年
      liunianganzhi: [],
      liunianshishen: [],
      liuniancanggan: [],
      liuniancangganshishen: [],
      // 流月
      liuyueganzhi: [],
      liuyueshishen: [],
      liuyuecanggan: [],
      liuyuecangganshishen: [],
      // 流日
      liuriganzhi: [],
      liurishishen: [],
      liuricanggan: [],
      liuricangganshishen: [],
      liuriganzhiYear: [],
      liuriganzhiMonth: [],
      // 是否显示置顶结果
      showFixedResult: false,
      // 六甲空亡
      kongwan: [],
      // 神煞
      nianzhushensha: [],
      yuezhushensha: [],
      rizhushensha: [],
      shizhushensha: [],
      // 展示计算结果
      showResult: false,
    };
  },
  methods: {
    calc() {
      // 没有输入日期的错误提示
      if (!this.solar) {
        alert("请输入正确的日期");
        return false;
      }
      // 提取公历日期分别转换为年月日三个数字
      this.year = parseInt(this.solar.substring(0, 4));
      this.month = parseInt(this.solar.match(/-(\S*)-/)[1]);
      this.day = parseInt(this.solar.substring(this.solar.length - 2));
      // 1900/1/31 至 2100/12/12
      if (this.year < 1901 || this.year > 2099) {
        alert("请输入 1901 年至 2099 年之间的日期");
        return false;
      }
      // 计算出农历日期
      const lunarObj = solarLunar.solar2lunar(this.year, this.month, this.day);
      this.lunarYear = lunarObj.lYear;
      this.lunarMonth = lunarObj.lMonth;
      this.lunarDay = lunarObj.lDay;
      this.lunar = `${this.lunarYear} 年 ${this.lunarMonth} 月 ${this.lunarDay} 日`;
      // 节气
      this.term = lunarObj.term == "" ? "无" : lunarObj.term;
      // 生肖
      this.zodiac = lunarCalendar.solarToLunar(
        this.year,
        this.month,
        this.day
      ).zodiac;
      // 三柱
      this.nianzhu = lunarObj.gzYear;
      this.yuezhu = lunarObj.gzMonth;
      this.rizhu = lunarObj.gzDay;
      // 三天干三地支
      this.niangan = this.nianzhu.substring(0, 1);
      this.nianzhi = this.nianzhu.substring(1, 2);
      this.yuegan = this.yuezhu.substring(0, 1);
      this.yuezhi = this.yuezhu.substring(1, 2);
      this.rigan = this.rizhu.substring(0, 1);
      this.rizhi = this.rizhu.substring(1, 2);
      // 时支
      for (const key in dizhi) {
        if (this.hour >= dizhi[key][6][0] && this.hour < dizhi[key][6][1]) {
          this.shizhi = key;
        }
      }
      // 时柱
      for (const key in shizhubiao) {
        if (this.rigan == key) {
          for (let i = 0; i < shizhubiao[key].length; i++) {
            if (this.shizhi == shizhubiao[key][i].substring(1, 2)) {
              this.shizhu = shizhubiao[key][i];
              // 时干
              this.shigan = this.shizhu.substring(0, 1);
            }
          }
        }
      }
      // 身强弱
      this.score = data.calcScore(
        this.niangan,
        this.nianzhi,
        this.yuegan,
        this.yuezhi,
        this.rigan,
        this.rizhi,
        this.shigan,
        this.shizhi
      );
      // 阴阳
      const riyuan_yinyang = data.calcTiangan(this.rigan)[0];
      const yueling_yinyang = data.calcDizhi(this.yuezhi)[0];
      this.yinyang = riyuan_yinyang == 1 ? "阳" : "阴";
      this.yuelingtongfan = riyuan_yinyang == yueling_yinyang ? "同" : "反";
      // 用神
      this.xiyongshen = data.calcYongshen(this.rigan, this.score)[0];
      this.jixiongshen = data.calcYongshen(this.rigan, this.score)[1];
      // 纳音
      this.nayinArray = [data.calcNayin(this.nianzhu), data.calcNayin(this.yuezhu), data.calcNayin(this.rizhu), data.calcNayin(this.shizhu)];
      // 十神
      let shishen = [
        data.calShishen(this.rigan + this.niangan),
        data.calShishen(this.rigan + this.yuegan),
        "日元",
        data.calShishen(this.rigan + this.shigan),
      ];
      this.shishen = shishen;
      // 藏干
      let canggan = [];
      for (const key in dizhi) {
        if (this.nianzhi == key) {
          canggan[0] = dizhi[key][5];
        }
        if (this.yuezhi == key) {
          canggan[1] = dizhi[key][5];
        }
        if (this.rizhi == key) {
          canggan[2] = dizhi[key][5];
        }
        if (this.shizhi == key) {
          canggan[3] = dizhi[key][5];
        }
      }
      this.canggan = canggan;
      // 藏干十神
      let cangganshishen = new Array();
      for (let i = 0; i < 4; i++) {
        cangganshishen[i] = new Array();
      }
      for (let i = 0; i < this.canggan.length; i++) {
        for (let a = 0; a < this.canggan[i].length; a++) {
          cangganshishen[i][a] = data.calShishen(
            this.rigan + this.canggan[i][a]
          );
        }
      }
      this.cangganshishen = cangganshishen;
      // 计算空亡
      let kongwan = [];
      for (let i = 0; i < 4; i++) {
        kongwan[i] = new Array();
      }
      for (let index in liujiakongwang) {
        for (let i = 0; i < 10; i++) {
          if (this.nianzhu == liujiakongwang[index][i]) {
            kongwan[0][0] = index.substring(0, 1);
            kongwan[0][1] = index.substring(1, 2);
          }
          if (this.yuezhu === liujiakongwang[index][i]) {
            kongwan[1][0] = index.substring(0, 1);
            kongwan[1][1] = index.substring(1, 2);
          }
          if (this.rizhu === liujiakongwang[index][i]) {
            kongwan[2][0] = index.substring(0, 1);
            kongwan[2][1] = index.substring(1, 2);
          }
          if (this.shizhu === liujiakongwang[index][i]) {
            kongwan[3][0] = index.substring(0, 1);
            kongwan[3][1] = index.substring(1, 2);
          }
        }
      }
      this.kongwan = kongwan;
      // 计算神煞
      this.nianzhushensha = [
        // 天德贵人
        data.calShensha(this.yuezhi, this.niangan, 0),
        data.calShensha(this.yuezhi, this.nianzhi, 0),
        // 月德贵人
        data.calShensha(this.yuezhi, this.niangan, 1),
        data.calShensha(this.yuezhi, this.nianzhi, 1),
        // 天乙贵人
        data.calShensha(this.niangan, this.nianzhi, 2),
        data.calShensha(this.rigan, this.nianzhi, 2),
        // 文昌贵人
        data.calShensha(this.niangan, this.nianzhi, 3),
        data.calShensha(this.rigan, this.nianzhi, 3),
        // 太极贵人
        data.calShensha(this.niangan, this.nianzhi, 4),
        data.calShensha(this.rigan, this.nianzhi, 4),
        // 驿马
        data.calShensha(this.rizhi, this.nianzhi, 5),
        // 华盖
        data.calShensha(this.rizhi, this.nianzhi, 6),
        // 将星
        data.calShensha(this.rizhi, this.nianzhi, 7),
        // 桃花
        data.calShensha(this.rizhi, this.nianzhi, 8),
        // 劫煞
        data.calShensha(this.rizhi, this.nianzhi, 9),
        // 血刃
        data.calShensha(this.rigan, this.nianzhi, 11),
        // 天罗
        data.calShensha(this.rizhi, this.nianzhi, 12),
        // 地网
        data.calShensha(this.rizhi, this.nianzhi, 13),
      ];
      this.yuezhushensha = [
        // 天德贵人
        data.calShensha(this.yuezhi, this.yuegan, 0),
        // 月德贵人
        data.calShensha(this.yuezhi, this.yuegan, 1),
        // 天乙贵人
        data.calShensha(this.niangan, this.yuezhi, 2),
        data.calShensha(this.rigan, this.yuezhi, 2),
        // 文昌贵人
        data.calShensha(this.niangan, this.yuezhi, 3),
        data.calShensha(this.rigan, this.yuezhi, 3),
        // 太极贵人
        data.calShensha(this.niangan, this.yuezhi, 4),
        data.calShensha(this.rigan, this.yuezhi, 4),
        // 驿马
        data.calShensha(this.nianzhi, this.yuezhi, 5),
        data.calShensha(this.rizhi, this.yuezhi, 5),
        // 华盖
        data.calShensha(this.nianzhi, this.yuezhi, 6),
        data.calShensha(this.rizhi, this.yuezhi, 6),
        // 将星
        data.calShensha(this.nianzhi, this.yuezhi, 7),
        data.calShensha(this.rizhi, this.yuezhi, 7),
        // 桃花
        data.calShensha(this.nianzhi, this.yuezhi, 8),
        data.calShensha(this.rizhi, this.yuezhi, 8),
        // 劫煞
        data.calShensha(this.nianzhi, this.yuezhi, 9),
        data.calShensha(this.rizhi, this.yuezhi, 9),
        // 灾煞
        data.calShensha(this.nianzhi, this.yuezhi, 10),
        // 血刃
        data.calShensha(this.rigan, this.yuezhi, 11),
        // 天罗
        data.calShensha(this.nianzhi, this.yuezhi, 12),
        data.calShensha(this.rizhi, this.yuezhi, 12),
        // 地网
        data.calShensha(this.nianzhi, this.yuezhi, 13),
        data.calShensha(this.rizhi, this.yuezhi, 13),
      ];
      this.rizhushensha = [
        // 天德贵人
        data.calShensha(this.yuezhi, this.rigan, 0),
        data.calShensha(this.yuezhi, this.rizhi, 0),
        // 月德贵人
        data.calShensha(this.yuezhi, this.rigan, 1),
        data.calShensha(this.yuezhi, this.rizhi, 1),
        // 天乙贵人
        data.calShensha(this.niangan, this.rizhi, 2),
        data.calShensha(this.rigan, this.rizhi, 2),
        // 文昌贵人
        data.calShensha(this.niangan, this.rizhi, 3),
        data.calShensha(this.rigan, this.rizhi, 3),
        // 太极贵人
        data.calShensha(this.niangan, this.rizhi, 4),
        data.calShensha(this.rigan, this.rizhi, 4),
        // 驿马
        data.calShensha(this.nianzhi, this.rizhi, 5),
        // 华盖
        data.calShensha(this.nianzhi, this.rizhi, 6),
        // 将星
        data.calShensha(this.nianzhi, this.rizhi, 7),
        // 桃花
        data.calShensha(this.nianzhi, this.rizhi, 8),
        // 劫煞
        data.calShensha(this.nianzhi, this.rizhi, 9),
        // 灾煞
        data.calShensha(this.nianzhi, this.rizhi, 10),
        // 血刃
        data.calShensha(this.rigan, this.rizhi, 11),
        // 天罗
        data.calShensha(this.nianzhi, this.rizhi, 12),
        // 地网
        data.calShensha(this.nianzhi, this.rizhi, 13),
      ];
      this.shizhushensha = [
        // 天德贵人
        data.calShensha(this.yuezhi, this.shigan, 0),
        data.calShensha(this.yuezhi, this.shizhi, 0),
        // 月德贵人
        data.calShensha(this.yuezhi, this.shigan, 1),
        data.calShensha(this.yuezhi, this.shizhi, 1),
        // 天乙贵人
        data.calShensha(this.niangan, this.shizhi, 2),
        data.calShensha(this.rigan, this.shizhi, 2),
        // 文昌贵人
        data.calShensha(this.niangan, this.shizhi, 3),
        data.calShensha(this.rigan, this.shizhi, 3),
        // 太极贵人
        data.calShensha(this.niangan, this.shizhi, 4),
        data.calShensha(this.rigan, this.shizhi, 4),
        // 驿马
        data.calShensha(this.nianzhi, this.shizhi, 5),
        data.calShensha(this.rizhi, this.shizhi, 5),
        // 华盖
        data.calShensha(this.nianzhi, this.shizhi, 6),
        data.calShensha(this.rizhi, this.shizhi, 6),
        // 将星
        data.calShensha(this.nianzhi, this.shizhi, 7),
        data.calShensha(this.rizhi, this.shizhi, 7),
        // 桃花
        data.calShensha(this.nianzhi, this.shizhi, 8),
        data.calShensha(this.rizhi, this.shizhi, 8),
        // 劫煞
        data.calShensha(this.nianzhi, this.shizhi, 9),
        data.calShensha(this.rizhi, this.shizhi, 9),
        // 灾煞
        data.calShensha(this.nianzhi, this.shizhi, 10),
        // 血刃
        data.calShensha(this.rigan, this.shizhi, 11),
        // 天罗
        data.calShensha(this.nianzhi, this.shizhi, 12),
        data.calShensha(this.rizhi, this.shizhi, 12),
        // 地网
        data.calShensha(this.nianzhi, this.shizhi, 13),
        data.calShensha(this.rizhi, this.shizhi, 13),
      ];
      // 行运开始的年份和岁数
      let xingyunshuishuDays = 0;
      for (let i = 0; i < 20; i++) {
        if (
          lunarCalendar.calendar(
            data.calNextDay(this.year, this.month, this.day, i)[0], // 年
            data.calNextDay(this.year, this.month, this.day, i)[1] // 月
          ).monthData[
            data.calNextDay(this.year, this.month, this.day, i)[2] - 1 // 日
          ].term
        ) {
          xingyunshuishuDays = i * 120;
          this.xingyunnian = parseFloat(((i * 4) / 12).toFixed(1));
        }
      }
      // 所有的行运岁数
      let xingyunnian = this.xingyunnian;
      let xingyunsuishu = [];
      for (let i = 0; i < 10; i++) {
        if (xingyunnian < 90) {
          xingyunsuishu[i] = xingyunnian;
          xingyunnian += 10;
        } else {
          break;
        }
      }
      this.xingyunsuishu = xingyunsuishu;
      // 大运干支， 10 年一大运
      let dayunganzhi = [];
      if (
        this.niangan == "甲" ||
        this.niangan == "丙" ||
        this.niangan == "戊" ||
        this.niangan == "庚" ||
        this.niangan == "壬"
      ) {
        if (this.male == "1") {
          // 阳年生男
          for (let i = 0; i < liushijiazi.length; i++) {
            if (this.yuezhu === liushijiazi[i]) {
              let yuezhu_index = i + 1;
              for (let a = 0; a < 9; a++) {
                if (yuezhu_index > 59 - a) {
                  yuezhu_index = -a;
                }
                dayunganzhi[a] = liushijiazi[yuezhu_index + a];
              }
            }
          }
        } else {
          // 阳年生女
          for (let i = 0; i < liushijiazi.length; i++) {
            if (this.yuezhu === liushijiazi[i]) {
              let yuezhu_index = i - 1;
              for (let a = 0; a < 9; a++) {
                if (yuezhu_index < 0 + a) {
                  yuezhu_index = 59 + a;
                }
                dayunganzhi[a] = liushijiazi[yuezhu_index - a];
              }
            }
          }
        }
      } else {
        if (this.male == "0") {
          // 阴年生女
          for (let i = 0; i < liushijiazi.length; i++) {
            if (this.yuezhu === liushijiazi[i]) {
              let yuezhu_index = i + 1;
              for (let a = 0; a < 9; a++) {
                if (yuezhu_index > 59 - a) {
                  yuezhu_index = -a;
                }
                dayunganzhi[a] = liushijiazi[yuezhu_index + a];
              }
            }
          }
        } else {
          // 阴年生男
          for (let i = 0; i < liushijiazi.length; i++) {
            if (this.yuezhu === liushijiazi[i]) {
              let yuezhu_index = i - 1;
              for (let a = 0; a < 9; a++) {
                if (yuezhu_index < 0 + a) {
                  yuezhu_index = 59 + a;
                }
                dayunganzhi[a] = liushijiazi[yuezhu_index - a];
              }
            }
          }
        }
      }
      let dayunganzhi_array = new Array();
      for (let i = 0; i < 9; i++) {
        dayunganzhi_array[i] = new Array();
      }
      for (let i = 0; i < dayunganzhi.length; i++) {
        dayunganzhi_array[i][0] = dayunganzhi[i].substring(0, 1);
        dayunganzhi_array[i][1] = dayunganzhi[i].substring(1, 2);
      }
      this.dayunganzhi = dayunganzhi_array;
      // 大运十神
      let dayunshishen = [];
      for (let i = 0; i < this.dayunganzhi.length; i++) {
        dayunshishen[i] = data.calShishen(this.rigan + this.dayunganzhi[i][0]);
      }
      this.dayunshishen = dayunshishen;
      // 大运藏干
      let dayuncanggan = [];
      for (let i = 0; i < this.dayunganzhi.length; i++) {
        for (const key in dizhi) {
          if (this.dayunganzhi[i][1] == key) {
            dayuncanggan[i] = dizhi[key][5];
          }
        }
      }
      this.dayuncanggan = dayuncanggan;
      // 大运藏干十神
      let dayuncangganshishen = new Array();
      for (let i = 0; i < 9; i++) {
        dayuncangganshishen[i] = new Array();
      }
      for (let i = 0; i < this.dayuncanggan.length; i++) {
        for (let a = 0; a < this.dayuncanggan[i].length; a++) {
          dayuncangganshishen[i][a] = data.calShishen(
            this.rigan + this.dayuncanggan[i][a]
          );
        }
      }
      this.dayuncangganshishen = dayuncangganshishen;
      // 流年的第一天
      this.liunianFirstDay = data.calNextDay(
        this.year,
        this.month,
        this.day,
        xingyunshuishuDays
      );
      // 默认计算第一个十年大运的流年
      this.calcLiunian(0);
      // 默认第一个流年的月的流日表
      let liuriArray = [];
      const days = data.getDays(this.liunianFirstDay[0], 1);
      for (let i = 0; i < days; i++) {
        liuriArray[i] = i + 1;
      }
      this.liuriArray = liuriArray;
      // 显示计算结果
      this.showResult = true;
    },
    // 选中行运岁数计算流年
    calcLiunian(index) {
      // 计算第 n 个大运的十年数组
      this.liunianArray = data.calcLiunian(this.liunianFirstDay[0], index);
      // 记录选中的行运岁数
      this.selectedOld = index;
      // 计算流年干支
      let liunianganzhi = [];
      for (let i = 0; i < 10; i++) {
        liunianganzhi[i] = new Array();
      }
      for (let i = 0; i < 10; i++) {
        if (this.liunianArray[i] > 2100) {
          liunianganzhi[i] = "空";
        } else {
          let ganzhi = solarLunar.solar2lunar(
            this.liunianArray[i],
            6,
            1
          ).gzYear;
          liunianganzhi[i][0] = ganzhi.substring(0, 1);
          liunianganzhi[i][1] = ganzhi.substring(1, 2);
        }
      }
      this.liunianganzhi = liunianganzhi;
      // 计算流年的十神
      let liunianshishen = [];
      for (let i = 0; i < 10; i++) {
        if (this.liunianganzhi[i] == "空") {
          liunianshishen[i] = "空";
        } else {
          liunianshishen[i] = data.calShishen(
            this.rigan + this.liunianganzhi[i][0]
          );
        }
      }
      this.liunianshishen = liunianshishen;
      // 计算流年的藏干
      let liuniancanggan = [];
      for (let i = 0; i < 10; i++) {
        if (this.liunianganzhi[i] == "空") {
          liuniancanggan[i] = "空";
        } else {
          for (const key in dizhi) {
            if (this.liunianganzhi[i][1] == key) {
              liuniancanggan[i] = dizhi[key][5];
            }
          }
        }
      }
      this.liuniancanggan = liuniancanggan;
      // 计算流年的藏干十神
      let liuniancangganshishen = new Array();
      for (let i = 0; i < 10; i++) {
        liuniancangganshishen[i] = new Array();
      }
      for (let i = 0; i < this.liuniancanggan.length; i++) {
        if (this.liuniancanggan[i] == "空") {
          liuniancangganshishen[i] = "空";
        } else {
          for (let a = 0; a < this.liuniancanggan[i].length; a++) {
            liuniancangganshishen[i][a] = data.calShishen(
              this.rigan + this.liuniancanggan[i][a]
            );
          }
        }
      }
      this.liuniancangganshishen = liuniancangganshishen;
      // 初始化流月
      this.calcLiuyue(this.liunianArray[0], 0);
      // 初始化流日
      this.calcLiuri(1, 0);
    },
    // 选中流年计算流月
    calcLiuyue(year, yearIndex) {
      // 记录选中的流年
      this.selectedYear = year;
      this.selectedYearIndex = yearIndex;
      // 计算流月干支
      let liuyueganzhi = [];
      for (let i = 0; i < 12; i++) {
        liuyueganzhi[i] = new Array();
      }
      for (let i = 0; i < 12; i++) {
        if (year > 2100) {
          liuyueganzhi[i] = "空";
        } else {
          let ganzhi = solarLunar.solar2lunar(year, i + 1, 20).gzMonth;
          liuyueganzhi[i][0] = ganzhi.substring(0, 1);
          liuyueganzhi[i][1] = ganzhi.substring(1, 2);
        }
      }
      this.liuyueganzhi = liuyueganzhi;
      // 计算流月的十神
      let liuyueshishen = [];
      for (let i = 0; i < 12; i++) {
        if (this.liuyueganzhi[i] == "空") {
          liuyueshishen[i] = "空";
        } else {
          liuyueshishen[i] = data.calShishen(
            this.rigan + this.liuyueganzhi[i][0]
          );
        }
      }
      this.liuyueshishen = liuyueshishen;
      // 计算流月的藏干
      let liuyuecanggan = [];
      for (let i = 0; i < 12; i++) {
        if (this.liuyueganzhi[i] == "空") {
          liuyuecanggan[i] = "空";
        } else {
          for (const key in dizhi) {
            if (this.liuyueganzhi[i][1] == key) {
              liuyuecanggan[i] = dizhi[key][5];
            }
          }
        }
      }
      this.liuyuecanggan = liuyuecanggan;
      // 计算流月的藏干十神
      let liuyuecangganshishen = new Array();
      for (let i = 0; i < 12; i++) {
        liuyuecangganshishen[i] = new Array();
      }
      for (let i = 0; i < this.liuyuecanggan.length; i++) {
        if (this.liuyuecanggan[i] == "空") {
          liuyuecangganshishen[i] = "空";
        } else {
          for (let a = 0; a < this.liuyuecanggan[i].length; a++) {
            liuyuecangganshishen[i][a] = data.calShishen(
              this.rigan + this.liuyuecanggan[i][a]
            );
          }
        }
      }
      this.liuyuecangganshishen = liuyuecangganshishen;
      // 计算流日
      this.calcLiuri(1, 0);
    },
    // 选中流月计算流日
    calcLiuri(month, monthIndex) {
      this.selectedMonth = month;
      this.selectedMonthIndex = monthIndex;
      let liuriArray = [];
      const days = data.getDays(this.selectedYear, month);
      for (let i = 0; i < days; i++) {
        liuriArray[i] = i + 1;
      }
      this.liuriArray = liuriArray;
      // 计算流日干支
      let liuriganzhi = [];
      for (let i = 0; i < liuriArray.length; i++) {
        liuriganzhi[i] = new Array();
      }
      for (let i = 0; i < liuriArray.length; i++) {
        if (this.selectedYear > 2100) {
          liuriganzhi[i] = "空";
        } else {
          let ganzhi = solarLunar.solar2lunar(
            this.selectedYear,
            month,
            liuriArray[i]
          ).gzDay;
          liuriganzhi[i][0] = ganzhi.substring(0, 1);
          liuriganzhi[i][1] = ganzhi.substring(1, 2);
        }
      }
      this.liuriganzhi = liuriganzhi;
      // 计算流日的十神
      let liurishishen = [];
      for (let i = 0; i < liuriArray.length; i++) {
        if (this.liuriganzhi[i] == "空") {
          liurishishen[i] = "空";
        } else {
          liurishishen[i] = data.calShishen(
            this.rigan + this.liuriganzhi[i][0]
          );
        }
      }
      this.liurishishen = liurishishen;
      // 计算流日的藏干
      let liuricanggan = [];
      for (let i = 0; i < liuriArray.length; i++) {
        if (this.liuriganzhi[i] == "空") {
          liuricanggan[i] = "空";
        } else {
          for (const key in dizhi) {
            if (this.liuriganzhi[i][1] == key) {
              liuricanggan[i] = dizhi[key][5];
            }
          }
        }
      }
      this.liuricanggan = liuricanggan;
      // 计算流日的藏干十神
      let liuricangganshishen = new Array();
      for (let i = 0; i < liuriArray.length; i++) {
        liuricangganshishen[i] = new Array();
      }
      for (let i = 0; i < this.liuricanggan.length; i++) {
        if (this.liuricanggan[i] == "空") {
          liuricangganshishen[i] = "空";
        } else {
          for (let a = 0; a < this.liuricanggan[i].length; a++) {
            liuricangganshishen[i][a] = data.calShishen(
              this.rigan + this.liuricanggan[i][a]
            );
          }
        }
      }
      this.liuricangganshishen = liuricangganshishen;
      // 因为农历和公历的关系，在流日的时候精确计算当日的六柱
      // 计算流日的年干
      let liuriganzhiYear = [];
      for (let i = 0; i < liuriArray.length; i++) {
        liuriganzhiYear[i] = new Array();
      }
      for (let i = 0; i < liuriArray.length; i++) {
        if (this.selectedYear > 2100) {
          liuriganzhiYear[i] = "空";
        } else {
          let ganzhi = solarLunar.solar2lunar(
            this.selectedYear,
            this.selectedMonth,
            liuriArray[i]
          ).gzYear;
          liuriganzhiYear[i][0] = ganzhi.substring(0, 1);
          liuriganzhiYear[i][1] = ganzhi.substring(1, 2);
        }
      }
      this.liuriganzhiYear = liuriganzhiYear;
      // 计算流日的月干
      let liuriganzhiMonth = [];
      for (let i = 0; i < liuriArray.length; i++) {
        liuriganzhiMonth[i] = new Array();
      }
      for (let i = 0; i < liuriArray.length; i++) {
        if (this.selectedYear > 2100) {
          liuriganzhiMonth[i] = "空";
        } else {
          let ganzhi = solarLunar.solar2lunar(
            this.selectedYear,
            this.selectedMonth,
            liuriArray[i]
          ).gzMonth;
          liuriganzhiMonth[i][0] = ganzhi.substring(0, 1);
          liuriganzhiMonth[i][1] = ganzhi.substring(1, 2);
        }
      }
      this.liuriganzhiMonth = liuriganzhiMonth;
    },
    // 滚动事件显示置顶分析结果
    handleScroll() {
      const distance =
        document.documentElement.scrollTop || document.body.scrollTop;
      if (document.body.offsetWidth < 640) {
        this.showFixedResult = false;
      } else {
        if (
          distance >= 361 &&
          this.showFixedResult === false &&
          document.body.offsetWidth > 640
        ) {
          this.showFixedResult = true;
        } else if (
          (distance < 361 && this.showFixedResult === true) ||
          document.body.offsetWidth < 640
        ) {
          this.showFixedResult = false;
        }
      }
    },
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
};
</script>

<style scoped lang="scss">
@import "../theme/lisa.css";
@import "../theme/lisa_mirror.css";
* {
  box-sizing: border-box;
}
$border-radius: 4px;
$max-width: 1200px;
body {
  background-color: var(--white);
  margin: 60px 20px;
  font-family: sans-serif;
}
input {
  border: 1px solid var(--grey_2);
  font-size: 15px;
  border-radius: $border-radius;
  color: var(--grey_9);
  height: 36px;
  padding: 0 8px;
  background-color: var(--white);
}
button {
  transition: background-color 0.18s;
  font-size: 15px;
  border-radius: $border-radius;
  height: 36px;
  padding: 0 12px;
  cursor: pointer;
  border: none;
  color: white;
  background-color: var(--blue_5);
  &:hover {
    background-color: var(--blue_6);
  }
}
select {
  cursor: pointer;
  border: 1px solid var(--grey_2);
  font-size: 15px;
  border-radius: $border-radius;
  background-color: var(--white);
  height: 36px;
  color: var(--grey_9);
  padding: 0 8px;
}
table {
  overflow: hidden;
  color: var(--grey_9);
  font-size: 14px;
  border-spacing: 0;
  border-radius: $border-radius;
  border: 1px solid var(--grey_2);
}
th + th,
td + td,
th + td {
  border-left: 1px solid var(--grey_2);
}
tbody {
  tr {
    th {
      border-top: 1px solid var(--grey_2);
    }
  }
}
th {
  line-height: 20px;
  padding: 10px 20px;
  background-color: var(--grey_0);
}
td {
  min-width: 76px;
  line-height: 20px;
  text-align: center;
  padding: 10px 20px;
  background-color: var(--white);
  border-top: 1px solid var(--grey_2);
}
.container {
  display: grid;
  grid-gap: 20px;
  max-width: $max-width;
  width: 100%;
  margin: 0 auto;
  margin-top: 20px;
  table {
    width: 100%;
  }
}
.header {
  display: grid;
  grid-template-columns: 2fr 2fr 1fr 1fr;
  grid-gap: 16px;
  input,
  button,
  select {
    width: 100%;
  }
  .title {
    display: none;
  }
}
.content {
  display: grid;
  grid-gap: 20px;
}
.lable {
  font-size: 12px;
}
.table_with_fixed_header {
  border: 1px solid var(--grey_2);
  border-radius: $border-radius;
  display: grid;
  grid-template-columns: 86px auto;
  overflow: hidden;
  .fixed_header {
    background-color: var(--grey_0);
    table {
      width: 100%;
      border: none;
      border-right: 1px solid var(--grey_2);
      border-radius: $border-radius 0 0 $border-radius;
      tr:first-child {
        th {
          border-top: none;
        }
      }
      tr:last-child {
        th {
          height: 81px;
        }
      }
      th {
        border-top: 1px solid var(--grey_2);
      }
    }
  }
  .scroll {
    overflow-x: auto;
    table {
      border-radius: 0 $border-radius $border-radius 0;
      border: none;
      td {
        min-width: 86px;
      }
    }
  }
}
.selected {
  color: var(--blue_6);
  background-color: var(--blue_2);
}
.selected_td {
  background-color: var(--blue_0);
}
.result {
  width: 100%;
  max-width: $max-width;
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
  box-shadow: 0 3px 30px rgba(0, 0, 0, 0.2);
}
.tab {
  transition: color 0.18s, background-color 0.18s;
  user-select: none;
  cursor: pointer;
  &:hover {
    color: var(--blue_9);
    background-color: var(--blue_3);
  }
}
.xingyunsuishu {
  grid-template-columns: 100px auto;
}
.空 {
  color: var(--grey_3);
}
// 天干地支属性颜色
.甲,
.乙,
.寅,
.卯 {
  color: var(--green_5);
}
.丙,
.丁,
.巳,
.午 {
  color: var(--red_5);
}
.戊,
.己,
.丑,
.辰,
.未,
.戌 {
  color: var(--yellow_5);
}
.庚,
.辛,
.申,
.酉 {
  color: var(--gold_5);
}
.壬,
.癸,
.亥,
.子 {
  color: var(--blue_5);
}
// 五行颜色
[class^="金"] {
  color: var(--gold_5);
}
[class^="木"] {
  color: var(--green_5);
}
[class^="水"] {
  color: var(--blue_5);
}
[class^="火"] {
  color: var(--red_5);
}
[class^="土"] {
  color: var(--yellow_5);
}
.yongshen {
  span + span {
    margin-left: 6px;
  }
}
// mobile
@media screen and (max-width: 640px) {
  th,
  td {
    padding: 10px;
    min-width: 20px;
  }
  // 输入信息
  .header {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    .title {
      display: block;
      line-height: 36px;
      margin-left: 4px;
      color: var(--grey_9);
    }
    .date {
      grid-column-start: 2;
      grid-column-end: 5;
    }
    .time {
      grid-column-start: 2;
      grid-column-end: 5;
    }
    .male {
      grid-column-start: 1;
      grid-column-end: 2;
    }
    .calc {
      grid-column-start: 2;
      grid-column-end: 5;
    }
  }
  // 农历信息
  .table_column {
    display: flex;
    tbody {
      flex-grow: 1;
    }
    tr {
      display: grid;
    }
    td {
      border: none;
      border-left: 1px solid var(--grey_2);
      & + td {
        border-top: 1px solid var(--grey_2);
      }
    }
    th + th {
      border: none;
      border-top: 1px solid var(--grey_2);
    }
  }
  .table_with_fixed_header {
    grid-template-columns: 56px auto;
  }
  // 行运岁数
  .xingyunsuishu.table_with_fixed_header {
    grid-template-columns: 80px auto;
  }
  // 流日
  .liuri.table_with_fixed_header {
    grid-template-columns: 68px auto;
  }
  // 神煞
  .shensha {
    tbody {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr 1fr;
    }
    td {
      border: none;
      border-top: 1px solid var(--grey_2);
    }
    td:nth-child(even) {
      border-left: 1px solid var(--grey_2);
    }
  }
}
</style>
