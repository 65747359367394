export default {
    tiangan: {
        甲: [1, '木', '东'],
        乙: [0, '木', '东'],
        丙: [1, '火', '南'],
        丁: [0, '火', '南'],
        戊: [1, '土', '中'],
        己: [0, '土', '中'],
        庚: [1, '金', '西'],
        辛: [0, '金', '西'],
        壬: [1, '水', '北'],
        癸: [0, '水', '北']
    },
    dizhi: {
        子: [1, '水', '北', '冬', '十一', ['癸'],
            [23, 25]
        ],
        丑: [0, '土', '北', '冬', '十二', ['己', '辛', '癸'],
            [1, 3]
        ],
        寅: [1, '木', '东', '春', '正', ['甲', '丙', '戊'],
            [3, 5]
        ],
        卯: [0, '木', '东', '春', '二', ['乙'],
            [5, 7]
        ],
        辰: [1, '土', '东', '春', '三', ['戊', '乙', '癸'],
            [7, 9]
        ],
        巳: [0, '火', '南', '夏', '四', ['丙', '戊', '庚'],
            [9, 11]
        ],
        午: [1, '火', '南', '夏', '五', ['丁', '己'],
            [11, 13]
        ],
        未: [0, '土', '南', '夏', '六', ['己', '丁', '乙'],
            [13, 15]
        ],
        申: [1, '金', '西', '秋', '七', ['庚', '壬', '戊'],
            [15, 17]
        ],
        酉: [0, '金', '西', '秋', '八', ['辛'],
            [17, 19]
        ],
        戌: [1, '土', '西', '秋', '九', ['戊', '辛', '丁'],
            [19, 21]
        ],
        亥: [0, '水', '北', '冬', '十', ['壬', '甲'],
            [21, 23]
        ]
    },
    shizhubiao: {
        甲: ['甲子', '乙丑', '丙寅', '丁卯', '戊辰', '己巳', '庚午', '辛未', '壬申', '癸酉', '甲戌', '乙亥'],
        己: ['甲子', '乙丑', '丙寅', '丁卯', '戊辰', '己巳', '庚午', '辛未', '壬申', '癸酉', '甲戌', '乙亥'],
        乙: ['丙子', '丁丑', '戊寅', '己卯', '庚辰', '辛巳', '壬午', '癸未', '甲申', '乙酉', '丙戌', '丁亥'],
        庚: ['丙子', '丁丑', '戊寅', '己卯', '庚辰', '辛巳', '壬午', '癸未', '甲申', '乙酉', '丙戌', '丁亥'],
        丙: ['戊子', '己丑', '庚寅', '辛卯', '壬辰', '癸巳', '甲午', '乙未', '丙申', '丁酉', '戊戌', '己亥'],
        辛: ['戊子', '己丑', '庚寅', '辛卯', '壬辰', '癸巳', '甲午', '乙未', '丙申', '丁酉', '戊戌', '己亥'],
        丁: ['庚子', '辛丑', '壬寅', '癸卯', '甲辰', '乙巳', '丙午', '丁未', '戊申', '己酉', '庚戌', '辛亥'],
        壬: ['庚子', '辛丑', '壬寅', '癸卯', '甲辰', '乙巳', '丙午', '丁未', '戊申', '己酉', '庚戌', '辛亥'],
        戊: ['壬子', '癸丑', '甲寅', '乙卯', '丙辰', '丁巳', '戊午', '己未', '庚申', '辛酉', '壬戌', '癸亥'],
        癸: ['壬子', '癸丑', '甲寅', '乙卯', '丙辰', '丁巳', '戊午', '己未', '庚申', '辛酉', '壬戌', '癸亥']
    },
    liushijiazi: [
        '甲子', '乙丑', '丙寅', '丁卯', '戊辰', '己巳', '庚午', '辛未', '壬申', '癸酉', '甲戌', '乙亥',
        '丙子', '丁丑', '戊寅', '己卯', '庚辰', '辛巳', '壬午', '癸未', '甲申', '乙酉', '丙戌', '丁亥',
        '戊子', '己丑', '庚寅', '辛卯', '壬辰', '癸巳', '甲午', '乙未', '丙申', '丁酉', '戊戌', '己亥',
        '庚子', '辛丑', '壬寅', '癸卯', '甲辰', '乙巳', '丙午', '丁未', '戊申', '己酉', '庚戌', '辛亥',
        '壬子', '癸丑', '甲寅', '乙卯', '丙辰', '丁巳', '戊午', '己未', '庚申', '辛酉', '壬戌', '癸亥'
    ],
    nayinbiao: {
        甲子: '海中金',
        乙丑: '海中金',
        丙寅: '炉中火',
        丁卯: '炉中火',
        戊辰: '大林木',
        己巳: '大林木',
        庚午: '路旁土',
        辛未: '路旁土',
        壬申: '剑锋金',
        癸酉: '剑锋金',
        甲戌: '山头火',
        乙亥: '山头火',
        丙子: '涧下水',
        丁丑: '涧下水',
        戊寅: '城墙土',
        己卯: '城墙土',
        庚辰: '白蜡金',
        辛巳: '白蜡金',
        壬午: '杨柳木',
        癸未: '杨柳木',
        甲申: '泉中水',
        乙酉: '泉中水',
        丙戌: '屋上土',
        丁亥: '屋上土',
        戊子: '霹雳火',
        己丑: '霹雳火',
        庚寅: '松柏木',
        辛卯: '松柏木',
        壬辰: '长流水',
        癸巳: '长流水',
        甲午: '沙中金',
        乙未: '沙中金',
        丙申: '山下火',
        丁酉: '山下火',
        戊戌: '平地木',
        己亥: '平地木',
        庚子: '壁上土',
        辛丑: '壁上土',
        壬寅: '金箔金',
        癸卯: '金箔金',
        甲辰: '佛灯火',
        乙巳: '佛灯火',
        丙午: '天河水',
        丁未: '天河水',
        戊申: '大驿土',
        己酉: '大驿土',
        庚戌: '钗钏金',
        辛亥: '钗钏金',
        壬子: '桑拓木',
        癸丑: '桑拓木',
        甲寅: '大溪水',
        乙卯: '大溪水',
        丙辰: '沙中土',
        丁巳: '沙中土',
        戊午: '天上火',
        己未: '天上火',
        庚申: '石榴木',
        辛酉: '石榴木',
        壬戌: '大海水',
        癸亥: '大海水'
    },
    liujiakongwang: {
        戌亥: ['甲子', '乙丑', '丙寅', '丁卯', '戊辰', '己巳', '庚午', '辛未', '壬申', '癸酉'],
        申酉: ['甲戌', '乙亥', '丙子', '丁丑', '戊寅', '己卯', '庚辰', '辛巳', '壬午', '癸未'],
        午未: ['甲申', '乙酉', '丙戌', '丁亥', '戊子', '己丑', '庚寅', '辛卯', '壬辰', '癸巳'],
        辰巳: ['甲午', '乙未', '丙申', '丁酉', '戊戌', '己亥', '庚子', '辛丑', '壬寅', '癸卯'],
        寅卯: ['甲辰', '乙巳', '丙午', '丁未', '戊申', '己酉', '庚戌', '辛亥', '壬子', '癸丑'],
        子丑: ['甲寅', '乙卯', '丙辰', '丁巳', '戊午', '己未', '庚申', '辛酉', '壬戌', '癸亥']
    },
    // 神煞表
    shensha: [
        // 吉神
        {
            name: '天德贵人', // 月支遇天干、地支
            table: ['寅丁', '卯申', '辰壬', '巳辛', '午亥', '未甲', '申癸', '酉寅', '戌丙', '亥乙', '子巳', '丑庚']
        },
        {
            name: '月德贵人', // 月支遇天干、地支
            table: ['寅丙', '午丙', '戌丙', '申壬', '子壬', '辰壬', '亥甲', '卯甲', '未甲', '巳庚', '酉庚', '丑庚'],
        },
        {
            name: '天乙贵人', // 年干、日干遇地支
            table: ['甲丑', '戊丑', '庚丑', '甲未', '戊未', '庚未', '乙子', '己子', '乙申', '己申', '丙亥', '丁亥', '丙酉', '丁酉', '壬巳', '癸巳', '壬卯', '癸卯', '辛午', '辛寅'],
        },
        {
            name: '文昌贵人', // 年干、日干遇地支
            table: ['甲巳', '乙午', '丙申', '丁酉', '戊申', '己酉', '庚亥', '辛子', '壬寅', '癸卯'],
        },
        {
            name: '太极贵人', // 年干、日干遇地支
            table: ['甲子', '甲午', '乙子', '乙午', '丙卯', '丙酉', '丁卯', '丁酉', '戊辰', '戊戌', '戊丑', '戊未', '己辰', '己戌', '己丑', '己未', '庚寅', '庚亥', '辛寅', '辛亥', '壬巳', '壬申', '癸巳', '癸申'],
        },
        // 平神
        {
            name: '驿马', // 年支、日支遇地支
            table: ['申寅', '子寅', '辰寅', '寅申', '午申', '戌申', '巳亥', '酉亥', '丑亥', '亥巳', '卯巳', '未巳'],
        },
        {
            name: '华盖', // 年支、日支遇地支
            table: ['寅戌', '午戌', '戌戌', '亥未', '卯未', '未未', '申辰', '子辰', '辰辰', '巳丑', '酉丑', '丑丑'],
        },
        {
            name: '将星', // 年支、日支遇地支
            table: ['子子', '丑酉', '寅午', '卯卯', '辰子', '巳酉', '午午', '未卯', '申子', '酉酉', '戌午', '亥卯'],
        },
        // 凶神
        {
            name: '桃花', // 年支、日支遇地支
            table: ['寅卯', '午卯', '戌卯', '申酉', '子酉', '辰酉', '亥子', '卯子', '未子', '巳午', '酉午', '丑午'],
        },
        {
            name: '劫煞', // 年支、日支遇地支
            table: ['子巳', '丑寅', '寅亥', '卯申', '辰巳', '巳寅', '午亥', '未申', '申巳', '酉寅', '戌亥', '亥申'],
        },
        {
            name: '灾煞', // 年支遇地支
            table: ['寅子', '午子', '戌子', '申午', '子午', '辰午', '亥酉', '卯酉', '未酉', '巳卯', '酉卯', '丑卯'],
        },
        {
            name: '血刃', // 日干遇地支
            table: ['甲卯', '乙辰', '丙午', '丁未', '戊午', '己未', '庚酉', '辛戌', '壬子', '癸丑'],
        },
        {
            name: '天罗', // 年支、日支遇地支
            table: ['戌亥', '亥戌'],
        },
        {
            name: '地网', // 年支、日支遇地支
            table: ['辰巳', '巳辰'],
        },
    ],
    // 计算神煞
    calShensha: function (key, match, index) {
        let all = [];
        for (let i = 0; i < this.shensha[index].table.length; i++) {
            if (key + match === this.shensha[index].table[i]) {
                all.push(this.shensha[index].name)
            }
        }
        return all
    },
    // 计算纳音
    calcNayin: function (zhu) {
        for (const key in this.nayinbiao) {
            if (zhu == key) {
                return this.nayinbiao[key]
            }
        }
    },
    // 计算八字强弱
    calcScore: function (niangan, nianzhi, yuegan, yuezhi, rigan, rizhi, shigan, shizhi) {
        let score = 0
        if (rigan == '庚' || rigan == '辛') {
            if (niangan == '庚' || niangan == '辛' || niangan == '戊' || niangan == '己') {
                score += 8
            } else {
                score -= 8
            }
            if (yuegan == '庚' || yuegan == '辛' || yuegan == '戊' || yuegan == '己') {
                score += 12
            } else {
                score -= 12
            }
            if (shigan == '庚' || shigan == '辛' || shigan == '戊' || shigan == '己') {
                score += 12
            } else {
                score -= 12
            }
            if (nianzhi == '丑' || nianzhi == '辰' || nianzhi == '未' || nianzhi == '申' || nianzhi == '酉' || nianzhi == '戌') {
                score += 4
            } else {
                score -= 4
            }
            if (yuezhi == '丑' || yuezhi == '辰' || yuezhi == '未' || yuezhi == '申' || yuezhi == '酉' || yuezhi == '戌') {
                score += 40
            } else {
                score -= 40
            }
            if (rizhi == '丑' || rizhi == '辰' || rizhi == '未' || rizhi == '申' || rizhi == '酉' || rizhi == '戌') {
                score += 12
            } else {
                score -= 12
            }
            if (shizhi == '丑' || shizhi == '辰' || shizhi == '未' || shizhi == '申' || shizhi == '酉' || shizhi == '戌') {
                score += 12
            } else {
                score -= 12
            }
        } else if (rigan == '甲' || rigan == '乙') {
            if (niangan == '甲' || niangan == '乙' || niangan == '壬' || niangan == '癸') {
                score += 8
            } else {
                score -= 8
            }
            if (yuegan == '甲' || yuegan == '乙' || yuegan == '壬' || yuegan == '癸') {
                score += 12
            } else {
                score -= 12
            }
            if (shigan == '甲' || shigan == '乙' || shigan == '壬' || shigan == '癸') {
                score += 12
            } else {
                score -= 12
            }
            if (nianzhi == '子' || nianzhi == '寅' || nianzhi == '卯' || nianzhi == '亥') {
                score += 4
            } else {
                score -= 4
            }
            if (yuezhi == '子' || yuezhi == '寅' || yuezhi == '卯' || yuezhi == '亥') {
                score += 40
            } else {
                score -= 40
            }
            if (rizhi == '子' || rizhi == '寅' || rizhi == '卯' || rizhi == '亥') {
                score += 12
            } else {
                score -= 12
            }
            if (shizhi == '子' || shizhi == '寅' || shizhi == '卯' || shizhi == '亥') {
                score += 12
            } else {
                score -= 12
            }
        } else if (rigan == '壬' || rigan == '癸') {
            if (niangan == '壬' || niangan == '癸' || niangan == '庚' || niangan == '辛') {
                score += 8
            } else {
                score -= 8
            }
            if (yuegan == '壬' || yuegan == '癸' || yuegan == '庚' || yuegan == '辛') {
                score += 12
            } else {
                score -= 12
            }
            if (shigan == '壬' || shigan == '癸' || shigan == '庚' || shigan == '辛') {
                score += 12
            } else {
                score -= 12
            }
            if (nianzhi == '子' || nianzhi == '亥' || nianzhi == '申' || nianzhi == '酉') {
                score += 4
            } else {
                score -= 4
            }
            if (yuezhi == '子' || yuezhi == '亥' || yuezhi == '申' || yuezhi == '酉') {
                score += 40
            } else {
                score -= 40
            }
            if (rizhi == '子' || rizhi == '亥' || rizhi == '申' || rizhi == '酉') {
                score += 12
            } else {
                score -= 12
            }
            if (shizhi == '子' || shizhi == '亥' || shizhi == '申' || shizhi == '酉') {
                score += 12
            } else {
                score -= 12
            }
        } else if (rigan == '丙' || rigan == '丁') {
            if (niangan == '丙' || niangan == '丁' || niangan == '甲' || niangan == '乙') {
                score += 8
            } else {
                score -= 8
            }
            if (yuegan == '丙' || yuegan == '丁' || yuegan == '甲' || yuegan == '乙') {
                score += 12
            } else {
                score -= 12
            }
            if (shigan == '丙' || shigan == '丁' || shigan == '甲' || shigan == '乙') {
                score += 12
            } else {
                score -= 12
            }
            if (nianzhi == '午' || nianzhi == '巳' || nianzhi == '寅' || nianzhi == '卯') {
                score += 4
            } else {
                score -= 4
            }
            if (yuezhi == '午' || yuezhi == '巳' || yuezhi == '寅' || yuezhi == '卯') {
                score += 40
            } else {
                score -= 40
            }
            if (rizhi == '午' || rizhi == '巳' || rizhi == '寅' || rizhi == '卯') {
                score += 12
            } else {
                score -= 12
            }
            if (shizhi == '午' || shizhi == '巳' || shizhi == '寅' || shizhi == '卯') {
                score += 12
            } else {
                score -= 12
            }
        } else if (rigan == '戊' || rigan == '己') {
            if (niangan == '戊' || niangan == '己' || niangan == '丙' || niangan == '丁') {
                score += 8
            } else {
                score -= 8
            }
            if (yuegan == '戊' || yuegan == '己' || yuegan == '丙' || yuegan == '丁') {
                score += 12
            } else {
                score -= 12
            }
            if (shigan == '戊' || shigan == '己' || shigan == '丙' || shigan == '丁') {
                score += 12
            } else {
                score -= 12
            }
            if (nianzhi == '午' || nianzhi == '巳' || nianzhi == '丑' || nianzhi == '辰' || nianzhi == '未' || nianzhi == '戌') {
                score += 4
            } else {
                score -= 4
            }
            if (yuezhi == '午' || yuezhi == '巳' || yuezhi == '丑' || yuezhi == '辰' || yuezhi == '未' || yuezhi == '戌') {
                score += 40
            } else {
                score -= 40
            }
            if (rizhi == '午' || rizhi == '巳' || rizhi == '丑' || rizhi == '辰' || rizhi == '未' || rizhi == '戌') {
                score += 12
            } else {
                score -= 12
            }
            if (shizhi == '午' || shizhi == '巳' || shizhi == '丑' || shizhi == '辰' || shizhi == '未' || shizhi == '戌') {
                score += 12
            } else {
                score -= 12
            }
        }
        return score
    },
    // 计算天干
    calcTiangan: function (t) {
        for (const key in this.tiangan) {
            if (t == key) {
                return this.tiangan[key]
            }
        }
    },
    // 计算地支
    calcDizhi: function (d) {
        for (const key in this.dizhi) {
            if (d == key) {
                return this.dizhi[key]
            }
        }
    },
    // 计算用神
    calcYongshen: function (rigan, score) {
        let xiyongshen = []
        let jixiongshen = []
        if (rigan == '庚' || rigan == '辛') {
            if (score > 0) {
                xiyongshen = ['水↑↑', '木↑', '火→']
                jixiongshen = ['土↓', '金↓↓']
            } else {
                xiyongshen = ['金↑↑', '土↑']
                jixiongshen = ['水→', '木↓', '火↓↓']
            }
        } else if (rigan == '甲' || rigan == '乙') {
            if (score > 0) {
                xiyongshen = ['火↑↑', '土↑', '金→']
                jixiongshen = ['水↓', '木↓↓']
            } else {
                xiyongshen = ['木↑↑', '水↑']
                jixiongshen = ['火→', '土↓', '金↓↓']
            }
        } else if (rigan == '壬' || rigan == '癸') {
            if (score > 0) {
                xiyongshen = ['木↑↑', '火↑', '土→']
                jixiongshen = ['金↓', '水↓↓']
            } else {
                xiyongshen = ['水↑↑', '金↑']
                jixiongshen = ['木→', '火↓', '土↓↓']
            }
        } else if (rigan == '丙' || rigan == '丁') {
            if (score > 0) {
                xiyongshen = ['土↑↑', '金↑', '水→']
                jixiongshen = ['木↓', '火↓↓']
            } else {
                xiyongshen = ['火↑↑', '木↑']
                jixiongshen = ['土→', '金↓', '水↓↓']
            }
        } else if (rigan == '戊' || rigan == '己') {
            if (score > 0) {
                xiyongshen = ['金↑↑', '水↑', '木→']
                jixiongshen = ['火↓', '土↓↓']
            } else {
                xiyongshen = ['土↑↑', '火↑']
                jixiongshen = ['金→', '水↓', '木↓↓']
            }
        }
        return [xiyongshen, jixiongshen]
    },
    // 计算 n 天后哪一天
    calNextDay: function (y, m, d, num) {
        let month_list = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        let date = month_list[m - 1] + ' ' + d + ',' + y;
        let today = new Date(date);
        let next_day = new Date(today);
        next_day.setDate(today.getDate() + num);
        return [next_day.getFullYear(), next_day.getMonth() + 1, next_day.getDate()]
    },
    // 计算十神
    calShishen: function (riyuan_tiangan) {
        const zhengyin = ['甲癸', '乙壬', '丙乙', '丁甲', '戊丁', '己丙', '庚己', '辛戊', '壬辛', '癸庚'];
        const shishen = ['甲丙', '乙丁', '丙戊', '丁己', '戊庚', '己辛', '庚壬', '辛癸', '壬甲', '癸乙'];
        const zhengguan = ['甲辛', '乙庚', '丙癸', '丁壬', '戊乙', '己甲', '庚丁', '辛丙', '壬己', '癸戊'];
        const zhengcai = ['甲己', '乙戊', '丙辛', '丁庚', '戊癸', '己壬', '庚乙', '辛甲', '壬丁', '癸丙'];
        const qisha = ['甲庚', '乙辛', '丙壬', '丁癸', '戊甲', '己乙', '庚丙', '辛丁', '壬戊', '癸己'];
        const pianyin = ['甲壬', '乙癸', '丙甲', '丁乙', '戊丙', '己丁', '庚戊', '辛己', '壬庚', '癸辛'];
        const shangguan = ['甲丁', '乙丙', '丙己', '丁戊', '戊辛', '己庚', '庚癸', '辛壬', '壬乙', '癸甲'];
        const jiecai = ['甲乙', '乙甲', '丙丁', '丁丙', '戊己', '己戊', '庚辛', '辛庚', '壬癸', '癸壬'];
        const bijian = ['甲甲', '乙乙', '丙丙', '丁丁', '戊戊', '己己', '庚庚', '辛辛', '壬壬', '癸癸'];
        const piancai = ['甲戊', '乙己', '丙庚', '丁辛', '戊壬', '己癸', '庚甲', '辛乙', '壬丙', '癸丁'];
        for (let i = 0; i < 10; i++) {
            if (riyuan_tiangan === zhengyin[i]) {
                return '正印'
            } else if (riyuan_tiangan === shishen[i]) {
                return '食神'
            } else if (riyuan_tiangan === zhengguan[i]) {
                return '正官'
            } else if (riyuan_tiangan === zhengcai[i]) {
                return '正财'
            } else if (riyuan_tiangan === qisha[i]) {
                return '七杀'
            } else if (riyuan_tiangan === pianyin[i]) {
                return '偏印'
            } else if (riyuan_tiangan === shangguan[i]) {
                return '伤官'
            } else if (riyuan_tiangan === jiecai[i]) {
                return '劫财'
            } else if (riyuan_tiangan === bijian[i]) {
                return '比肩'
            } else if (riyuan_tiangan === piancai[i]) {
                return '偏财'
            }
        }
    },
    // 计算流年
    calcLiunian: function (liunianFirstYear, index) {
        let liunianArray = [];
        for (let i = 0; i < 10; i++) {
            liunianArray[i] = liunianFirstYear + index * 10 + i
        }
        return liunianArray
    },
    // 计算某月天数
    getDays: function (year, month) {
        const days = new Date(year, month, 0)
        return days.getDate()
    }
}